export function searchFn(data, keyword, keys) {
  keyword = keyword || '';
  keyword = keyword.toLowerCase().trim();
  const keyword1 = keyword.replace(/ +(?= )/g, '');
  const arr = [undefined, null];

  for (const key of keys) {
    const value = arr.includes(data[key]) ? '' : data[key].toString().toLowerCase();
    if (value.includes(keyword) || value.includes(keyword1)) {
      return true;
    }
  }

  return false;
}

export function sortString(a, b) {
  a = a || '';
  b = b || '';
  return a.localeCompare(b);
}

export const setFlag    = (field, flag) =>  field |= flag;
export const clearFlag  = (field, flag) =>  field &= ~flag;
export const toggleFlag = (field, flag) =>  field ^= flag;
export const hasFlag    = (field, flag) =>  flag === 0 ? field === flag : ((field & flag) !== 0);

export const getUpdatedStatus = (field, flag, isTrue) => isTrue ? field | flag : field & ~flag;
//User Task Flags
export const TechnicalManager = 1 << 0;
export const Accounts = 1 << 1;
export const SalesMarketing = 1 << 2;
export const WeldingExaminer = 1 << 3;

//Asset Flags
export const Thermometer = 1 << 0;
export const AmpVoltMeter = 1 << 1;
export const WhiteLightMeter = 1 << 2;

export const shortenNDTColumnNames = (ndt) =>{
  switch(ndt)
  {
    case "Visual Inspection Test (VT)": return "VT";
    case "Phased Array Ultrasonic Testing (PAUT)": return "PAUT";
    case "Eddy Current Testing (ET)": return "ET";
    case "Magnetic Particle Testing (MT)": return "MT";
    case "Penetrant Testing (PT)": return "PT";
    case "Radiography Testing (RT)": return "RT";
    case "Ultrasonic Testing (UT)": return "UT";
    default: return ndt;  
  }
}

export const getCorrectStatusName = (statusNo) => {

    switch(statusNo)
    {
        case 0:
            return "Pending";
        case 1:
            return "Reviewing";
        case 200:
            return "Passed";
        case 201:
            return "Failed";
        case 202:
            return "Not Tested";
        default:
            return "None"
    }
}

export const returnColour = (result) =>{
    switch(result)
    {
        case 200: return "#B0DFC4";
        case 201: return "#FFC7CE";
        case 202: return "#A6A6A6";
        default: return "white";
    }
};