import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from 'antd';
import { toastr } from 'react-redux-toastr';
import { connect, useDispatch } from 'react-redux'
import { setCurrentUser } from '../../redux/newReduxActions/newUserActions';
import * as Roles from './../../appConstants';
import * as actionUser from './../../redux/actions/actionUser';
import * as api from './../../utils/api';
import useApplicationInstance from '../../common-components/Hooks/useApplicationInstance';

import './Login.scss';

function Login(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { setCurrentUser } = props;

    const [ isShowModal, setShowModal] = useState(false);

    const [message, setMessage] = useState();
    const [lock, setLock] = useState(true); // lock until we can determine status from server
 
    const [loginUser, setLoginUser] = useState({
        Email: '',
        PassWord: ''
    });

    const instance = useApplicationInstance();

    useEffect(() => {
        api.getLoginControl()
            .then(response => {
                const data = response.data.data;
                setLock(data.lock);
                if (data.lock) {
                    setMessage("The system is temporarily closed for maintenance. Please try again later.");
                } else {
                    setMessage(data.message);
                }
            })
            .catch(err => {});
    },[]);

    const showModal = () => {
        setShowModal(true);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const resendNewPasswordEmail = () => {
        api.resendEmail({ email: loginUser.Email })
            .catch(err => {})
            .finally(() => handleCancel());
    }

    const postLogin = (event) => {
        event.preventDefault();
        api.login(JSON.stringify(loginUser))
            .then(async response => {
                const userData = response.data.data;
                const isActived = userData.isNewAccount;
                const code = userData.code || '';
                if (isActived) {
                    showModal();
                } else {
                    localStorage.setItem('token', userData.token);
                    let expiredTime =  new Date(userData.expiry);
                    expiredTime = Date.parse(expiredTime);
                    localStorage.setItem('expiredTime', expiredTime);
                    
                    // setCurrentUser(userData);
                    dispatch(setCurrentUser(userData));

                    if (code) {
                        toastr.error('You account is being asked to change your password');
                        localStorage.setItem('forcePasswordCode', code);
                        navigate('/authentication/resetpassword');
                    }
                    else {
                        if(userData.role === Roles.ROLE_CONTRACTOR_ID)
                        {
                            navigate('/home');
                            await new Promise(res => setTimeout(res, 50)); 
                            navigate(0);
                        }
                        else
                        {
                            navigate('/landingpage');
                            await new Promise(res => setTimeout(res, 50)); 
                            navigate(0);
                        }
                    }
                }
            })
            .catch(err =>{})
    }

    const handleOnChange = (e) => {
        let target = e.target;
        loginUser[target.name] = target.value;
        setLoginUser(user => { return { ...user, [target.name]:target.value } });
    }

    const checkboxContainer = {
        display: 'flex',
        position: 'relative'
    }

    const showMessage = (message && 
        <div className='login-message'>
            <i className='fas fa-info-circle fa-2x login-message-icon'></i>
            <div className='login-message-text'>{message}</div>
        </div>
    )

    return (
        <React.Fragment>
            <div className={`Login ${instance.class}`}>
                <div className="login login-v2" data-pageload-addclass="animated fadeIn">
                    <div className="login-header">
                        <div className="brand">
                            <span className="logo"></span> Sign In
                            <small></small>
                        </div>
                    </div>                    
                    <div className="login-content">
                        <form onSubmit={(e)=>e.preventDefault()}>
                            <div className="form-group m-b-20">
                                <input type="email" disabled={lock} className="form-control input-lg" name="Email" placeholder="Email Address" required onChange={handleOnChange} />
                            </div>
                            <div className="form-group m-b-20">
                                <input type="password" disabled={lock} className="form-control input-lg" name="PassWord" placeholder="Password" required onChange={handleOnChange} />
                            </div>
                            <div className="m-b-20" style={checkboxContainer}>
                                <Link disabled={lock} to="/authentication/forgotpassword">Forgotten Password</Link>
                            </div>
                            <div className="login-buttons m-t-20">
                                {/* <button  type='submit' disabled={lock} onClick={(event) => postLogin(event)} className="btn btn-success btn-block btn-lg" style={{ display: 'flex', justifyContent:'center'}}>
                                    <span> Sign me in  </span>
                                </button> */}
                                <Button disabled={lock} onClick={postLogin} className="btn btn-success btn-block btn-lg" style={{ display: 'flex', justifyContent:'center'}}>
                                    Sign in
                                </Button>
                            </div>
                        </form>
                    </div>
                    {showMessage}
                </div>
            </div>
            <Modal
                title="Warning"
                open={isShowModal}
                onOk={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                >
                <p>Click <span className="Login__content-link" onClick={() => resendNewPasswordEmail()}>here</span> to resend activation email link</p>
            </Modal>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    const { alert, setting } = state;
    return { alert, setting };
}

function mapDispatchToProps(dispatch) {
    return {
        setCurrentUser:(user) => 
            dispatch(actionUser.setCurrentUser(user)),
            
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
