import { lazy } from 'react';
import { Accounts } from '../utils/others';
import { ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_REGIONAL_MANAGER, 
         ROLE_COORDINATOR, ROLE_CONTRACTOR, ROLE_CANDIDATE } from './../appConstants';

import Login from '../components/Login/Login'; // need to statically import *something* or CSS goes screwy
//import LandingPage from '../components/LandingPage/LandingPage';
const LandingPage = lazy(() => import('../components/LandingPage/LandingPage'))
// Assets -----------------------------------------------------------------------------------------
const AssetTable = lazy(()  => import('../components/ManageAsset/AssetTable' /* webpackChunkName: 'AssetTable' */));
const AssetForm = lazy(()  => import('../components/ManageAsset/AssetForm' /* webpackChunkName: 'AssetForm' */));
const ReservationCalendar = lazy(()  => import('../components/ManageAsset/ReservationCalendar' /* webpackChunkName: 'ReservationCalendar' */));
// Audit Logs -------------------------------------------------------------------------------------
const ManageAuditLog = lazy(()  => import('../components/ManageAuditLog/ManageAuditLog' /* webpackChunkName: 'ManageAuditLog' */));
const EmailSysLogReport = lazy(()  => import('../components/ManageReport/EmailSysLogReport' /* webpackChunkName: 'EmailSysLogReport' */));
// Authentication ---------------------------------------------------------------------------------
const ForgotPassword = lazy(()  => import('../components/ForgotPassword/ForgotPassword' /* webpackChunkName: 'ForgotPassword' */));
const ResetPassword = lazy(()  => import('../components/ResetPassword/ResetPassword' /* webpackChunkName: 'ResetPassword' */)); 
// Clients ----------------------------------------------------------------------------------------
const ManageClient = lazy(()  => import('../components/ManageClient/ManageClient' /* webpackChunkName: 'ManageClient' */));
const ManageClientForm = lazy(()  => import('../components/ManageClient/ManageClientForm' /* webpackChunkName: 'ManageClientForm' */));
// Competency -------------------------------------------------------------------------------------
const ManageCompetency = lazy(()  => import('../components/Competencies/ManageCompetency' /* webpackChunkName: 'ManageCompetency' */));
const ManageCompetencyForm = lazy(()  => import('../components/Competencies/ManageCompetencyForm' /* webpackChunkName: 'ManageCompetencyForm' */));
// Contractors ------------------------------------------------------------------------------------
const ManageContractor = lazy(()  => import('../components/ManageContractor/ManageContractor' /* webpackChunkName: 'ManageContractor' */));
const ManageContractorForm = lazy(()  => import('../components/ManageContractor/ManageContractorForm' /* webpackChunkName: 'ManageContractorForm' */));
const ContractorMissingDocuments = lazy(()  => import('../components/ManageContractor/MissingDocumentsReport' /* webpackChunkName: 'ContractorMissingDocuments' */));
const ContractorExpiringDocuments = lazy(()  => import('../components/ManageContractor/ExpiringDocumentsReport' /* webpackChunkName: 'ContractorExpiringDocuments' */));
const MatrixReport = lazy(()  => import('../components/ManageReport/MatrixReport' /* webpackChunkName: 'MatrixReport' */));
const ContractorMapSearch = lazy(()  => import('../components/Maps/ContractorMapSearch' /* webpackChunkName: 'ContractorMapSearch' */));
const CountryDisciplineReport = lazy(()  => import('../components/ManageContractor/CountryDisciplineReport' /* webpackChunkName: 'CountryDisciplineReport' */));
const NewContractorReport = lazy(()  => import('../components/ManageContractor/NewContractorReport' /* webpackChunkName: 'NewContractorReport' */));
const ContractorRatingReport = lazy(() => import('../components/ManageContractor/RatingReport' /* webpackChunkName: 'ContractorRatingReport' */));
// Contractor Portal ------------------------------------------------------------------------------
const ContractorLibrary = lazy(()  => import('../components/ContractorPortal/ContractorLibrary' /* webpackChunkName: 'ContractorLibrary' */));
const ContractorDetailsForm = lazy(()  => import('../components/ContractorPortal/ContractorDetailsForm' /* webpackChunkName: 'ContractorDetailsForm' */));
// Discipline -------------------------------------------------------------------------------------
const ManageDisciplineForm = lazy(()  => import('../components/ManageDiscipline/ManageDisciplineForm' /* webpackChunkName: 'ManageDisciplineForm' */));
const ManageDiscipline = lazy(()  => import('../components/ManageDiscipline/ManageDiscipline' /* webpackChunkName: 'ManageDiscipline' */));
// Edit Dashboard ---------------------------------------------------------------------------------
const ManageDashboard = lazy(()  => import('../components/ManageDashboard/ManageDashboard' /* webpackChunkName: 'ManageDashboard' */));
// Exams
const ManageExamTable = lazy(() => import('../components/ManageExam/ManageExamTable'));
const ManageExamForm = lazy(() => import('../components/ManageExam/ManageExamForm'));
const ManageExamAttemptTable = lazy(() => import('../components/ManageExam/ManageExamAttemptTable'));
const ManageExamAttemptForm = lazy(() => import('../components/ManageExam/ManageExamAttemptForm'));
const ManageExamReviewAttemptForm = lazy(() => import('../components/ManageExam/ManageExamReviewAttempt'));
// Examiners
const ManageExaminerTable = lazy(() => import('../components/ManageExaminer/ManageExaminerTable' /* webpackChunkName: 'ManageExaminerTable' */));
const ManageExaminerForm = lazy(() => import('../components/ManageExaminer/ManageExaminerForm' /* webpackChunkName: 'ManageExaminerTable' */));
// Home -------------------------------------------------------------------------------------------
const Home = lazy(()  => import('../components/Home/HomeDirector' /* webpackChunkName: 'HomeDirector' */));
// Certification Home -----------------------------------------------------------------------------
const CertHome = lazy(() => import('../components/Certification/Home/HomeDirector'))
// Job --------------------------------------------------------------------------------------------
const ManageJob = lazy(()  => import('../components/ManageJob/ManageJob' /* webpackChunkName: 'ManageJob' */));
const ManageJobForm = lazy(()  => import('../components/ManageJob/ManageJobForm' /* webpackChunkName: 'ManageJobForm' */));
const NoContractorReport = lazy(()  => import('../components/ManageJob/NoContractorReport' /* webpackChunkName: 'NoContractorReport' */));
const EnquiryToQuoteKPI = lazy(()  => import('../components/ManageJob/EnquiryToQuoteKPI' /* webpackChunkName: 'EnquiryToQuoteKPI' */));
const EnquiryToQuoteCoordinator = lazy(()  => import('../components/ManageJob/EnquiryToQuoteCoordinator' /* webpackChunkName: 'EnquiryToQuoteCoordinator' */));
const JobCalendar = lazy(()  => import('../components/ManageJob/JobCalendar' /* webpackChunkName: 'JobCalendar' */));
const JobScopeReport = lazy(()  => import('../components/ManageJob/JobScopeReport' /* webpackChunkName: 'JobScopeReport' */));
// Job Report -------------------------------------------------------------------------------------
const TableJobReport = lazy(()  => import('../components/ManageJobReports/TableJobReport' /* webpackChunkName: 'TableJobReport' */));
const JobReportForm = lazy(()  => import('../components/ManageJobReports/JobReportForm' /* webpackChunkName: 'JobReportForm' */));
// Keywords ---------------------------------------------------------------------------------------
const Keywords = lazy(() => import('../components/ManageKeyword/ManageKeyword' /* webpackChunkName: 'Keywords' */));
// Labels -----------------------------------------------------------------------------------------
const LabelTable = lazy(() =>  import('../components/ManageLabels/LabelTable' /* webpackChunkName: 'LabelTable' */));
const LabelForm = lazy(() =>  import('../components/ManageLabels/LabelForm' /* webpackChunkName: 'LabelForm' */));
// Landing Page -----------------------------------------------------------------------------------
//const landingPage = lazy(() => import('../components/LandingPage/LandingPage'))
// Potential Contractor ---------------------------------------------------------------------------
const ManagePotentialContractor = lazy(()  => import('../components/ManagePotentialContractor/ManagePotentialContractor' /* webpackChunkName: 'ManagePotentialContractor' */));
const ManagePotentialContractorForm = lazy(()  => import('../components/ManagePotentialContractor/ManagePotentialContractorForm' /* webpackChunkName: 'ManagePotentialContractorForm' */));
// Profile ----------------------------------------------------------------------------------------
const Profile = lazy(()  => import('../components/Profile/Profile' /* webpackChunkName: 'Profile' */));
// Projects ---------------------------------------------------------------------------------------
// See NECIT001-162... const ProjectTable = lazy(() => import('../components/ManageProjects/ProjectTable' /* webpackChunkName: 'ProjectTable' */));
// See NECIT001-162... const ProjectForm = lazy(() => import('../components/ManageProjects/ProjectForm' /* webpackChunkName: 'ProjectForm' */));
// Qualification ----------------------------------------------------------------------------------
const ManageQualificationForm = lazy(()  => import('../components/ManageQualification/ManageQualificationForm' /* webpackChunkName: 'ManageQualificationForm' */));
const ManageQualification = lazy(()  => import('../components/ManageQualification/ManageQualification' /* webpackChunkName: 'ManageQualification' */));
// Region -----------------------------------------------------------------------------------------
const ManageRegionForm = lazy(()  => import('../components/ManageRegion/ManageRegionForm' /* webpackChunkName: 'ManageRegionForm' */));
const ManageRegion = lazy(()  => import('../components/ManageRegion/ManageRegion' /* webpackChunkName: 'ManageRegion' */));
// Registration -----------------------------------------------------------------------------------
const RegistrationEditDirector = lazy(()  => import('../components/ManageRegistration/RegistrationEditDirector' /* webpackChunkName: 'RegistrationEditDirector' */));
const InProgressApplications = lazy(()  => import('../components/ManageRegistration/InProgressApplications' /* webpackChunkName: 'InProgressApplications' */));
const UnsuccessfulApplications = lazy(()  => import('../components/ManageRegistration/UnsuccessfulApplications' /* webpackChunkName: 'UnsuccessfulApplications' */));
// Reports ----------------------------------------------------------------------------------------
const InactiveClientReport = lazy(() => import('../components/ManageClient/InactiveClientReport' /* webpackChunkName: 'InactiveClientReport' */));
const ClientCreditCheckReport = lazy(() => import('../components/ManageClient/ClientCreditCheckReport' /* webpackChunkName: 'ClientCreditCheckReport' */));
const InactiveSupplierReport = lazy(() => import('../components/ManageSupplier/InactiveSupplierReport' /* webpackChunkName: 'InactiveSupplierReport' */));
const UnapprovedSupplierReport = lazy(() => import('../components/ManageSupplier/UnapprovedSupplierReport' /* webpackChunkName: 'UnapprovedSupplierReport' */));
const DuplicateContractorReport = lazy(() => import('../components/ManageContractor/DuplicateContractorReport' /* webpackChunkName: 'DuplicateContractorReport' */));
const UnapprovedContractorReport = lazy(() => import('../components/ManageContractor/UnapprovedContractorReport' /* webpackChunkName: 'UnapprovedContractorReport' */));
const EmailLogReport = lazy(() => import('../components/ManageReport/EmailLogReport' /* webpackChunkName: 'EmailLogReport' */));
const WorkCalendarReport = lazy(() => import('../components/ManageReport/WorkCalendarReport' /* webpackChunkName: 'WorkCalendarReport' */));
const WeldJobTracker = lazy(() => import('../components/ManageReport/WeldJobTracker' /* webpackChunkName: 'WeldJobTracker' */));
// Search -----------------------------------------------------------------------------------------
const SearchPage = lazy(() => import('../components/Search/SearchPage' /* webpackChunkName: 'SearchPage' */));
// Service ----------------------------------------------------------------------------------------
const ManageService = lazy(() => import('../components/ManageService/ManageService' /* webpackChunkName: 'ManageService' */));
const ManageServiceForm = lazy(() => import('../components/ManageService/ManageServiceForm' /* webpackChunkName: 'ManageServiceForm' */));
// Service Orders ---------------------------------------------------------------------------------
const TableServiceOrder = lazy(() => import('../components/ManageServiceOrder/TableServiceOrder' /* webpackChunkName: 'TableServiceOrder' */));
const ServiceOrderForm = lazy(() => import('../components/ManageServiceOrder/ServiceOrderForm' /* webpackChunkName: 'ServiceOrderForm' */));
const TableServiceOrderArchive = lazy(() => import('../components/ManageServiceOrder/TableServiceOrderArchive' /* webpackChunkName: 'TableServiceOrderArchive' */ ))
// Specials ---------------------------------------------------------------------------------------
const PageNotFound = lazy(() => import('../components/PageNotFound/PageNotFound' /* webpackChunkName: 'PageNotFound' */));
// Certification Suppliers --------------------------------------------------------------------------------------
//const CertificationManageSupplier = lazy(() => import('../components/Certification/ManageSupplier/ManageSuppliers' /* webpackChunkName: 'ManageSupplier' */));
// Suppliers --------------------------------------------------------------------------------------
const ManageSupplier = lazy(() => import('../components/ManageSupplier/ManageSupplier' /* webpackChunkName: 'ManageSupplier' */));
const ManageSupplierForm = lazy(() => import('../components/ManageSupplier/ManageSupplierForm' /* webpackChunkName: 'ManageSupplierForm' */));
const SupplierMissingDocuments = lazy(() => import('../components/ManageSupplier/MissingDocumentsReport' /* webpackChunkName: 'SupplierMissingDocuments' */));
const SupplierExpiringDocuments = lazy(() => import('../components/ManageSupplier/ExpiringDocumentsReport' /* webpackChunkName: 'SupplierExpiringDocuments' */));
const BusinessUnitTable = lazy(() => import('../components/ManageSupplier/BusinessUnitTable' /* webpackChunkName: 'BusinessUnitTable' */));
// Timesheets -------------------------------------------------------------------------------------
const TimesheetTrackerTable = lazy(() => import('../components/ManageTimesheets/TimesheetTrackerTable' /* webpackChunkName: 'TimesheetTrackerTable' */));
const ManageTimesheetTrackerForm = lazy(() => import('../components/ManageTimesheets/ManageTimesheetTrackerForm' /* webpackChunkName: 'ManageTimesheetTrackerForm' */));
const TimesheetEntryTable = lazy(() => import('../components/ManageTimesheets/TimesheetEntryTable' /* webpackChunkName: 'TimesheetEntryTable' */));
const TimesheetEntry = lazy(() => import('../components/ManageTimesheets/TimesheetEntryForm' /* webpackChunkName: 'TimesheetEntryForm' */));
const ContractorInvoiceTable = lazy(() => import('../components/ManageTimesheets/ContractorInvoiceTable' /* webpackChunkName: 'ContractorInvoiceTable' */));
const ContractorInvoiceEntryForm = lazy(() => import('../components/ManageTimesheets/ContractorInvoiceEntryForm' /* webpackChunkName: 'ContractorInvoiceEntryForm' */));
const ContractorInvoiceForm = lazy(() => import('../components/ManageTimesheets/ContractorInvoiceForm' /* webpackChunkName: 'ContractorInvoiceForm' */));
const PrepareBilling = lazy(() => import('../components/ManageTimesheets/PrepareBilling' /* webpackChunkName: 'PrepareBilling' */));
const ClientBillingForm = lazy(() => import('../components/ManageTimesheets/ClientBillingForm' /* webpackChunkName: 'ClientBillingForm' */));
const ClientBillingTable = lazy(() => import('../components/ManageTimesheets/ClientBillingTable' /* webpackChunkName: 'ClientBillingTable' */));
// Users ------------------------------------------------------------------------------------------
const ManageUser = lazy(() => import('../components/ManageUser/ManageUser' /* webpackChunkName: 'ManageUser' */));
const ManageUserForm = lazy(() => import('../components/ManageUser/ManageUserForm' /* webpackChunkName: 'ManageUserForm' */));
// Vendors ----------------------------------------------------------------------------------------
const ManageVendorForm = lazy(() => import('../components/ManageVendor/ManageVendorForm' /* webpackChunkName: 'ManageVendorForm' */));
const ManageVendor = lazy(() => import('../components/ManageVendor/ManageVendor' /* webpackChunkName: 'ManageVendor' */));
// Welders ----------------------------------------------------------------------------------------
const ManageWeldersTable = lazy(() => import('../components/ManageWelder/ManageWelders'));
const ManageWelderForm = lazy(() => import('../components/ManageWelder/ManageWelderForm'));
// Testing Home --------------------------------------------------------------------------------------
const ManageTestingHome = lazy(() => import('../components/TestingHome/Home'));
// Testing Enquiry --------------------------------------------------------------------------------------
const ManageTestingEnquiryTable = lazy(() => import('../components/ManageTestingEnquiry/WeldTestingTable'));
const ManageTestingEnquiryForm = lazy(() => import('../components/ManageTestingEnquiry/WeldTestingForm'));

// Weld Jobs --------------------------------------------------------------------------------------
const WeldEnquiryTable = lazy(() => import('../components/ManageWeldEnquiry/WeldEnquiryTable' /* webpackChunkName: 'WeldEnquiryTable' */));
const WeldEnquiryForm = lazy(() => import('../components/ManageWeldEnquiry/WeldEnquiryForm' /* webpackChunkName: 'WeldEnquiryForm' */));
const PresetOrderFormForm = lazy(() => import('../components/ManagePresetJobForms/ManagePresetJobFormForm'));
const PresetOrderFormTable = lazy(() => import('../components/ManagePresetJobForms/ManagePresetJobFormTable'));
const PresetWeldJobForm = lazy(() => import('../components/ManagePresetWeldJob/PresetWeldJobForm'));
const PresetWeldJobTable = lazy(() => import('../components/ManagePresetWeldJob/PresetWeldJobTable'));
const WeldJobForm =lazy(() => import('../components/ManageWeldJob/WeldJobForm' /* webpackChunkName: 'WeldJobForm' */));
const WeldJobCalendar =lazy(() => import('../components/ManageWeldJob/WeldJobCalendar' /* webpackChunkName: 'WeldJobCalendar' */));
const WeldToDoTable = lazy(() => import('../components/ManageWeldEnquiry/WeldToDoTable' /* webpackChunkName: 'WeldToDoTable' */));
const WeldTimesheetTable = lazy(() => import('../components/ManageWeldEnquiry/documents/WeldTimesheetTable' /* webpackChunkName: 'WeldTimesheetTable' */));
// WeldpWPS ----------------------------------------------------------------------------------
const PwpsTable = lazy(() => import('../components/ManageWeldEnquiry/PwpsTable' /* webpackChunkName: 'PwpsTable' */));
const PwpsForm = lazy(() => import('../components/ManageWeldEnquiry/PwpsForm' /* webpackChunkName: 'PwpsForm' */));
// WeldMaterials ----------------------------------------------------------------------------------
const ManageWeldMaterial = lazy(() => import('../components/ManageWeldMaterial/WeldMaterialTable' /* webpackChunkName: 'ManageWeldMaterial' */));
const WeldMaterialForm = lazy(() => import('../components/ManageWeldMaterial/WeldMaterialForm' /* webpackChunkName: 'WeldMaterialForm' */));

// Workflow ---------------------------------------------------------------------------------------
const ManageTasks = lazy(() => import('../components/Workflow/ManageTasks' /* webpackChunkName: 'ManageTasks' */));
const TaskList = lazy(() => import('../components/Workflow/TaskList' /* webpackChunkName: 'TaskList' */));

// Layout -----------------------------------------------------------------------------------------
const Layout = lazy(() => import('../common-components/AppLayout/AppLayout'))
/* ************************************************************************* *\
 * ROUTER CONFIGURATION
 *
 * path:      The route URL.  Must begin with /. 
 *            Start of URL must be unique for menu matching, e.g. /home and
 *            /home/myportal will both match /home if they are menu items.
 * lazy: The react lazy to show.  Must be imported above.
 * title:     The page title when this route is active.
 *            (optional) defaults to company's short name
 * inexact:   True = partial route matches will be accepted
 *            (optional) defaults to false.
 * public:    True = page can be seen when not logged in.
 *            (optional) defaults to false.
 * roles:     Array of role names that are permitted to view this route.
 *            (optional) defaults to all roles permitted if not specified.
\* ************************************************************************* */

// Authorization policy arrays
const AUTH_ADMIN = [ROLE_ADMIN];
const AUTH_ADMIN_ACCOUNTS = [ROLE_ADMIN, "Accounts"];
const AUTH_COMPANY_MANAGER = [ROLE_ADMIN, ROLE_COMPANY_MANAGER];
const AUTH_MANAGER = [ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_REGIONAL_MANAGER];
const AUTH_INTERNAL = [ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_REGIONAL_MANAGER, ROLE_COORDINATOR];
const AUTH_CONTRACTOR = [ROLE_CONTRACTOR];                // NB: Not visible to Admins in menus
const AUTH_CANDIDATE = [ROLE_CANDIDATE];                  // NB: Not visible to Admins in menus
const AUTH_INTERNAL_OR_CONTRACTOR = [ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_REGIONAL_MANAGER, ROLE_COORDINATOR, ROLE_CONTRACTOR];

// List entries alphabetically by path, unless they need to be in a specific position
const routes = [
  {
    element: <Layout />,
    children: [
  // --------------------------------------------------------------------------------------- Certification Assets
  { path: "certification/assets",                           
    title: "Assets",                    
    element: <AssetTable/>, 
    roles: AUTH_INTERNAL 
  },
  // --------------------------------------------------------------------------------------- Assets
  { path: "assets",                           
    title: "Asset",                    
    element: <AssetTable/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "assets/add",                       
    title: "New Asset",                
    element: <AssetForm/>,
    roles: AUTH_INTERNAL 
  },
  { path: "assets/edit/:id",                  
    title: "Edit Asset",               
    element: <AssetForm/>,
    roles: AUTH_INTERNAL 
  },
  { path: "asset/reservations",                  
    title: "Asset Reservations",               
    element: <ReservationCalendar/>,
    roles: AUTH_INTERNAL 
  },
  // ----------------------------------------------------------------------------------- Audit Logs
  { path: "audit-logs",                        
    title: "Audit Logs",                
    element: <ManageAuditLog/>, 
    roles: AUTH_COMPANY_MANAGER 
  },
  { path: "email-logs",                              
    title: "Email System Log",                   
    element: <EmailSysLogReport/>,
    roles: AUTH_ADMIN 
  },
  // ------------------------------------------------------------------------------- Authentication
  { path: "authentication/forgotpassword",     
    title: "Forgotten Password",                   
    element: <ForgotPassword/>, 
    public: true 
  },
  { path: "authentication/resetpassword",      
    title: "Reset Password",            
    element: <ResetPassword/>, 
    public: true 
  },
  { path: "authentication/signin",             
    title: "Sign In",                   
    element: <Login/>, 
    public: true 
  },
  // -------------------------------------------------------------------------------------- Certification Clients
  { path: "certification/clients",                           
    title: "Clients",                    
    element: <ManageClient/>, 
    roles: AUTH_INTERNAL 
  },
  // -------------------------------------------------------------------------------------- Clients
  { path: "clients",                           
    title: "Clients",                    
    element: <ManageClient/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "clients/add",                       
    title: "Client Add",                
    element: <ManageClientForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "clients/edit/:id",                  
    title: "Client Edit",               
    element: <ManageClientForm/>, 
    roles: AUTH_INTERNAL 
  },
  // ----------------------------------------------------------------------------------- Competency
  { path: "competency",                        
    title: "Competency",                
    element: <ManageCompetency/>, 
    roles: AUTH_ADMIN 
  },
  { path: "competency/add",                    
    title: "Competency Add",            
    element: <ManageCompetencyForm/>, 
    roles: AUTH_ADMIN 
  },
  { path: "competency/edit/:id",               
    title: "Competency Edit",           
    element: <ManageCompetencyForm/>, 
    roles: AUTH_ADMIN
  },
  // ---------------------------------------------------------------------------------- Contractors
  { path: "contractors/all",                       
    title: "Contractor",                
    element: <ManageContractor/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "contractors/add",                   
    title: "Contractor Add",            
    element: <ManageContractorForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "contractors/detail/:id",            
    title: "Contractor Detail",         
    element: <ManageContractorForm  readonly={true} />, 
  },
  { path: "contractors/edit/:id",              
    title: "Contractor Edit",           
    element: <ManageContractorForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "contractors/mapsearch",
    title: "Contractor Search",           
    element: <ContractorMapSearch/>, 
    roles: AUTH_INTERNAL 
  },
  // ---------------------------------------------------------------------------- Contractor Portal
  { path: "contractorportal/registration",
    title: "Registration",           
    element: <RegistrationEditDirector/>,
    roles: AUTH_CANDIDATE
  },
  { path: "contractorportal/profile",
    title: "My Profile",           
    element: <ContractorDetailsForm/>, 
    roles: AUTH_CONTRACTOR
  },
  { path: "contractorportal/library",
    title: "Library",           
    element: <ContractorLibrary/>, 
    roles: AUTH_CONTRACTOR
  },
  { path: "contractorportal/timesheets",
    title: "Timesheets",           
    element: <TimesheetEntryTable/>, 
    roles: AUTH_CONTRACTOR
  },
  { path: "contractorportal/invoices",
    title: "Invoices",
    element: <ContractorInvoiceTable/>,
    roles: AUTH_CONTRACTOR
  },
  // ------------------------------------------------------------------------------------ Dashboard
  { path: "editdashboard",                     
    title: "Dashboard",                 
    element: <ManageDashboard/>, 
    roles: AUTH_COMPANY_MANAGER 
  },
  // ----------------------------------------------------------------------------------- Discipline
  { path: "disciplines",                       
    title: "Discipline",                
    element: <ManageDiscipline/>, 
    roles: AUTH_ADMIN 
  },
  { path: "disciplines/add",                   
    title: "Discipline Add",            
    element: <ManageDisciplineForm/>, 
    roles: AUTH_ADMIN 
  },
  { path: "disciplines/edit/:id",              
    title: "Discipline Edit",           
    element: <ManageDisciplineForm/>, 
    roles: AUTH_ADMIN 
  },
  // ----------------------------------------------------------------------------------------- Exams
  { path: "exams/all",
    title: "All Exams",
    element: <ManageExamTable/>,
    roles: AUTH_MANAGER,
  },
  { path: "exams/add",
    title: "Add Exam",
    element: <ManageExamForm/>,
    roles: AUTH_MANAGER,
  },
  { path: "exams/:id",
    title: "Edit Exam",
    element: <ManageExamForm/>,
    roles: AUTH_MANAGER,
  },
  { path: "exams/attempts",
    title: "Exam Attempts",
    element: <ManageExamAttemptTable/>,
    roles: AUTH_MANAGER,
  },
  { path: "exams/attempt/:id",
    title: "Exam Attempt",
    element: <ManageExamReviewAttemptForm/>,
    roles: AUTH_INTERNAL,
  },
  { path: "exams/:id/attempt/:attemptId",
    title: "Exam Attempt",
    element: <ManageExamAttemptForm/>,
    roles: AUTH_INTERNAL,
  },
  // ----------------------------------------------------------------------------------------- Examiner
  { path: "certification/necitexaminer/all",
    title: 'All Examiners',
    element: <ManageExaminerTable/>,
    roles: AUTH_MANAGER
  },
  { path: "certification/necitexaminer/add",
    title: "Add Examiner",
    element: <ManageExaminerForm/>,
    roles: AUTH_MANAGER
  },
  { path: "certification/necitexaminer/:id",
    title: "Edit Examiner",
    element: <ManageExaminerForm/>,
    roles: AUTH_MANAGER
  },
  // ----------------------------------------------------------------------------------------- Certification Home
  { path: "certification/home",                              
    title: "Certification Home",                      
    element: <CertHome/>,
    roles: AUTH_INTERNAL,
  },
  // ----------------------------------------------------------------------------------------- Home
  { path: "home",                              
    title: "Home",                      
    element: <Home />,
  },
  // ------------------------------------------------------------------------------------------ Certification Job
  { path: "certification/job-calendar",                     
    title: "Job Calendar",                  
    element: <JobCalendar/>, 
    roles: AUTH_INTERNAL 
  },
  // ------------------------------------------------------------------------------------------ Job
  { path: "jobs",                              
    title: "Job",                       
    element: <ManageJob/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "jobs/add",                          
    title: "Job Add",                   
    element: <ManageJobForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "jobs/edit/:id",                     
    title: "Job Edit",                  
    element: <ManageJobForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "job-calendar",                     
    title: "Job Calendar",                  
    element: <JobCalendar/>, 
    roles: AUTH_INTERNAL 
  },
  // ----------------------------------------------------------------------------------- Job Report
  { path: "jobreports",
    title: "Job Reports",
    element: <TableJobReport/>,
    roles: AUTH_INTERNAL
  },  
  { path: "jobreports/:id",
    title: "Job Report",
    element: <JobReportForm/>,
    roles: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------- Keywords
  { path: "keywords",
    title: "Keywords",
    element: <Keywords/>,
    roles: AUTH_MANAGER
  },
    // ------------------------------------------------------------------------------------- Certification Keywords
    { path: "certification/keywords",
    title: "Keywords",
    element: <Keywords/>,
    roles: AUTH_MANAGER
  },
  // --------------------------------------------------------------------------------------- Labels
  { path: "labelnames",
    title: "Attachment Labels",
    element: <LabelTable/>,
    roles: AUTH_ADMIN
  },
  { path: "labelnames/:value",
    title: "Attachment Label",
    element: <LabelForm/>,
    roles: AUTH_ADMIN
  },
  // ------------------------------------------------------------------------- Potential Contractor
  { path: "potentialcontractor/edit/:id",      
    title: "Potential Contractor Edit", 
    element: <ManagePotentialContractorForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "potentialcontractor/:id",  //id is the jobid      
    title: "Potential",                 
    element: <ManagePotentialContractor/>, 
    roles: AUTH_INTERNAL 
  },
  // -------------------------------------------------------------------------------------- Profile
  { path: "profile/:id",                       
    title: "Profile",                   
    element: <Profile/> 
  },
  // ------------------------------------------------------------------------------------- Projects
  /* 
   * Projects functionality mothballed. See NECIT001-162
  { path: "/projects",                       
    title: "Projects",                   
    element: ProjectTable,
    roles: AUTH_INTERNAL, 
  },
  { path: "/projects/:id",                       
    title: "Project",
    element: ProjectForm,
    roles: AUTH_INTERNAL, 
  },
  */
  // -------------------------------------------------------------------------------- Qualification
  { path: "qualifications",                    
    title: "Qualification",             
    element: <ManageQualification/>, 
    roles: AUTH_ADMIN 
  },
  { path: "qualifications/edit/:id",           
    title: "Qualification Edit",        
    element: <ManageQualificationForm/>, 
    roles: AUTH_ADMIN 
  },
  // --------------------------------------------------------------------------------------- Region
  { path: "regions",                           
    title: "Region",                    
    element: <ManageRegion/>, 
    roles: AUTH_ADMIN 
  },
  { path: "regions/add",                       
    title: "Region Add",                
    element: <ManageRegionForm/>, 
    roles: AUTH_ADMIN 
  },
  { path: "regions/edit/:id",                  
    title: "Region Edit",               
    element: <ManageRegionForm/>, 
    roles: AUTH_ADMIN 
  },
  //---------------------------------------------------------------------------------- Registration
  { path: 'registrations/edit/:id',
    title: 'Registration Details',
    element: <RegistrationEditDirector/>,
    roles: AUTH_INTERNAL
  },
  { path: 'registrations/inprogress',
    title: 'Registrations In Progress',
    element: <InProgressApplications/>,
    roles: AUTH_INTERNAL
  },
  { path: 'registrations/unsuccessful',
    title: 'Unsuccessful Applications',
    element: <UnsuccessfulApplications/>,
    roles: AUTH_INTERNAL
  },
  // -------------------------------------------------------------------------------------- Reports
  // JOBS
  { path: 'reports/jobs/enquiry-to-quote',
    title: 'Enquiry To Quote KPI',
    element: <EnquiryToQuoteKPI/>,
    roles: AUTH_ADMIN // AUTH_MANAGER
  },
  { path: 'reports/jobs/enquiry-to-quote/:from/:to/:id',
    title: 'Enquiry To Quote KPI',
    element: <EnquiryToQuoteCoordinator/>,
    roles: AUTH_ADMIN // AUTH_MANAGER
  },
  { path: 'reports/jobs/no-contractor',
    title: 'Jobs Without Contractor',
    element: <NoContractorReport/>,
    roles: AUTH_ADMIN // AUTH_MANAGER
  },
  { path: "reports/jobs/scope",                     
    title: "Job Scope",                  
    element: <JobScopeReport/>, 
    roles: AUTH_ADMIN // AUTH_INTERNAL 
  },
  { path: "reports/jobs/calendar",
    title: "Work Calendar",
    element: <WorkCalendarReport/>,
    roles: AUTH_ADMIN_ACCOUNTS, // AUTH_INTERNAL,
    tasks: [Accounts]
  },
  // WELDING
  { path: "reports/welding/jobtracker",
    title: "Welding Job Tracker",
    element: <WeldJobTracker/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  // CONTRACTORS
  { path: "reports/contractors/duplicate",
    title: "Duplicate Contractors Report",   
    element: <DuplicateContractorReport/>, 
    roles: AUTH_ADMIN // AUTH_INTERNAL 
  },
  { path: "reports/contractors/unapproved", 
    title: "Unapproved Contractors Report",   
    element: <UnapprovedContractorReport/>, 
    roles: AUTH_ADMIN // AUTH_INTERNAL 
  },
  { path: 'reports/contractors/missing-documents',
    title: 'Contractor Missing Documents',
    element: <ContractorMissingDocuments/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  { path: 'reports/contractors/expiring-documents',
    title: 'Contractor Expiring Documents',
    element: <ContractorExpiringDocuments/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  { path: 'reports/contractors/matrix',
    title: 'Contractor Matrix',
    element: <MatrixReport/>,
    roles: AUTH_ADMIN // AUTH_MANAGER
  },
  { path: 'reports/contractors/countries',
    title: 'Contractor Countries',
    element: <CountryDisciplineReport/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  { path: 'reports/contractors/created',
    title: 'New Contractors',
    element: <NewContractorReport/>,
    roles: AUTH_ADMIN // AUTH_MANAGER
  },
  { path: 'reports/contractors/rating',
    title: 'Contractor Ratings',
    element: <ContractorRatingReport/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  // SUPPLIERS
  { path: 'reports/suppliers/missing-documents',
    title: 'Supplier Missing Documents',
    element: <SupplierMissingDocuments/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  { path: 'reports/suppliers/expiring-documents',
    title: 'Supplier Expiring Documents',
    element: <SupplierExpiringDocuments/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL
  },
  { path: "reports/suppliers/inactive",         
    title: "Inactive Supplier Report",   
    element: <InactiveSupplierReport/>, 
    roles: AUTH_ADMIN // AUTH_MANAGER 
  },
  { path: "reports/suppliers/unapproved",         
    title: "Unapproved Supplier Report",   
    element: <UnapprovedSupplierReport/>, 
    roles: AUTH_ADMIN // AUTH_MANAGER 
  },
  // CLIENTS
  { path: "reports/clients/inactive",           
    title: "Inactive Client Report",     
    element: <InactiveClientReport/>, 
    roles: AUTH_ADMIN // AUTH_MANAGER
  },
  { path: "reports/clients/creditcheck",           
    title: "Client Credit Status Report",     
    element: <ClientCreditCheckReport/>, 
    roles: AUTH_ADMIN // AUTH_MANAGER
  },  
  // EMAILS
  { path: "reports/emails/recent",                              
    title: "Recent Emails",                      
    element: <EmailLogReport/>,
    roles: AUTH_ADMIN // AUTH_INTERNAL 
  },
  // --------------------------------------------------------------------------------------- Search
  { path: "search",                          
    title: "Search",                   
    element: <SearchPage/>, 
    roles: AUTH_INTERNAL
  },
  // -------------------------------------------------------------------------------------- Service
  { path: "services",                          
    title: "Service",                   
    element: <ManageService/>, 
    roles: AUTH_ADMIN 
  },
  { path: "services/add",                      
    title: "Service Add",               
    element: <ManageServiceForm/>, 
    roles: AUTH_ADMIN 
  },
  { path: "services/edit/:id",                 
    title: "Service Edit",              
    element: <ManageServiceForm/>, 
    roles: AUTH_ADMIN 
  },
  // ------------------------------------------------------------------------------- Service Orders
  { path: 'serviceorders',
    title: 'Service Orders',
    element: <TableServiceOrder/>,
    roles: AUTH_INTERNAL
  },
  { path: 'serviceorders/:id',
    title: 'Service Order',
    element: <ServiceOrderForm/>,
    roles: AUTH_INTERNAL
  },
  { path: 'serviceordersarchive',
    title: 'Service Order Archive',
    element: <TableServiceOrderArchive/>,
    roles: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------ Certification Suppliers
  { path: "certification/suppliers",                         
    title: "Suppliers",                  
    element: <ManageSupplier/>, 
    roles: AUTH_INTERNAL 
  },
  // ------------------------------------------------------------------------------------ Suppliers
  { path: "suppliers",                         
    title: "Supplier",                  
    element: <ManageSupplier/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "suppliers/add",                     
    title: "Supplier Add",              
    element: <ManageSupplierForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "suppliers/edit/:id",                
    title: "Supplier Edit",             
    element: <ManageSupplierForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "businessunits",                
    title: "Business Units",             
    element: <BusinessUnitTable/>, 
    roles: AUTH_ADMIN 
  },
  // ----------------------------------------------------------------------------------- Timesheets
  { path: "timesheets",                             
    title: "Timesheets",                      
    element: <TimesheetTrackerTable/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "timesheets/:id",                             
    title: "Timesheet",                      
    element: <ManageTimesheetTrackerForm/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "timesheetentries",                             
    title: "Timesheet Entries",                      
    element: <TimesheetEntryTable/>, 
    roles: AUTH_INTERNAL 
  },
  { path: "timesheet/edit/:id",
    title: "Timesheet Entry",
    element: <TimesheetEntry/>,
    roles: AUTH_INTERNAL_OR_CONTRACTOR
  },
  { path: "contractorinvoices/all",
    title: "Contractor Invoices",
    element: <ContractorInvoiceTable/>,
    roles: AUTH_INTERNAL
  },
  { path: "contractorinvoices/edit/:id",
    title: "Contractor Invoice",
    element: <ContractorInvoiceForm/>,
    roles: AUTH_INTERNAL_OR_CONTRACTOR
  },
  { path: "contractorinvoices/new",
    title: "New Invoice",
    element: <ContractorInvoiceEntryForm/>,
    roles: AUTH_INTERNAL_OR_CONTRACTOR
  },
  { path: "billing/prepare",
    title: "Prepare Billing",
    element: <PrepareBilling/>,
    roles: AUTH_INTERNAL
  },
  { path: "billing/edit/:id",
    title: "Client Billing",
    element: <ClientBillingForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "billing/all",
    title: "Client Billing",
    element: <ClientBillingTable/>,
    roles: AUTH_INTERNAL
  },
  // ---------------------------------------------------------------------------------------- Users
  { path: "users",                             
    title: "User",                      
    element: <ManageUser/>, 
    roles: AUTH_MANAGER 
  },
  { path: "users/add",                         
    title: "User Add",                  
    element: <ManageUserForm/>, 
    roles: AUTH_MANAGER 
  },
  { path: "users/edit/:id",                    
    title: "User Edit",                 
    element: <ManageUserForm/>, 
    roles: AUTH_MANAGER 
  },
  // -------------------------------------------------------------------------------------- Vendors
  { path: "vendors",
    title: "Vendor",
    element: <ManageVendor/>,
    roles: AUTH_INTERNAL
  },
  { path: "vendors/add",
    title: "Vendor Add",
    element: <ManageVendorForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "vendors/edit/:id",
    title: "Vendor Edit",
    element: <ManageVendorForm/>,
    roles: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------- Welders
  {
    path: "certification/welders",
    title: "All Welders",
    element: <ManageWeldersTable/>,
    roles: AUTH_INTERNAL
  },
  {
    path: "certification/welders/edit/:id",
    title: "Edit Welder",
    element: <ManageWelderForm/>,
    roles: AUTH_INTERNAL
  },
  {
    path: "certification/welders/add",
    title: "Add Welder",
    element: <ManageWelderForm/>,
    roles: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------- TestingHome
  { path:'testing/home',
    title: 'Testing Home',
    element: <ManageTestingHome/>,
    role: AUTH_INTERNAL,
  },
  // ------------------------------------------------------------------------------------- WeldTestEnquiry
  { path: 'testing/enquiries',
    title: 'Testing Requests',
    element: <ManageTestingEnquiryTable/>,
    role: AUTH_INTERNAL
  },
  { path: 'testing/enquiry',
    title: 'Testing Requests',
    element: <ManageTestingEnquiryForm/>,
    role: AUTH_INTERNAL
  },
  { path: 'testing/enquiry/edit/:id',
    title: 'Testing Requests',
    element: <ManageTestingEnquiryForm/>,
    role: AUTH_INTERNAL
  },
  { path: 'testing/enquiry/job/:id',
    title: 'Testing Requests',
    element: <ManageTestingEnquiryForm/>,
    role: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------- WeldJobs
  { path: "certification/weldjobs",
    title: "Weld Requests",
    element: <WeldEnquiryTable/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldjobs/enquiry",
    title: "Weld Enquiry",
    element: <WeldEnquiryForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldjobs/edit/:id",
    title: "Weld Request",
    element: <WeldEnquiryForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldjobs/jobs/:id",
    title: "Weld Job",
    element: <WeldJobForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "testing/weldjobs/jobs/:id",
    title: "Weld Job",
    element: <WeldJobForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/presetjoborderforms",
    title: "Preset Job Forms",
    element: <PresetOrderFormTable/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/presetjoborderforms/add",
    title: "Preset Job Form",
    element: <PresetOrderFormForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/presetjoborderforms/edit/:id",
    title: "Preset Job Form",
    element: <PresetOrderFormForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/presetweldjobs",
    title: "Preset Job Forms",
    element: <PresetWeldJobTable/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/presetweldjob/add",
    title: "Preset Job Form",
    element: <PresetWeldJobForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/presetweldjob/edit/:id",
    title: "Preset Job Form",
    element: <PresetWeldJobForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldtodo",
    title: "Weld To Do List",
    element: <WeldToDoTable/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldcalendar",
    title: "Welding Calendar",
    element: <WeldJobCalendar/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldtimesheets",
    title: "Welding Timesheets",
    element: <WeldTimesheetTable/>,
    roles: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------- WeldpWPS
  { path: "pwps",
    title: "pWPS",
    element: <PwpsTable/>,
    roles: AUTH_INTERNAL
  },
  { path: "pwps/add",
    title: "pWPS",
    element: <PwpsForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "pwps/edit/:id",
    title: "pWPS",
    element: <PwpsForm/>,
    roles: AUTH_INTERNAL
  },
  // -------------------------------------------------------------------------------- WeldMaterials
  { path: "certification/weldmaterials",
    title: "Weld Materials",
    element: <ManageWeldMaterial/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldmaterials/new",
    title: "New Weld Material",
    element: <WeldMaterialForm/>,
    roles: AUTH_INTERNAL
  },
  { path: "certification/weldmaterials/edit/:id",
    title: "Weld Material",
    element: <WeldMaterialForm/>,
    roles: AUTH_INTERNAL
  },
  // ------------------------------------------------------------------------------------- Workflow
  { 
    path: 'workflow/tasks',
    title: 'My Tasks',
    element: <TaskList/>,
  },
  { 
    path: 'workflow/managetasks',
    title: 'Manage Tasks',
    element: <ManageTasks/>,
    roles: AUTH_MANAGER 
  },
    ]
  },
  // ------------------------------------------------------------------------------------- Landing Page
  {
    path: "/landingpage",
    title: "Landing Page",
    element: <LandingPage />,
    roles: AUTH_INTERNAL
  },     
  // ------------------------------------------------------------------------------------- Specials
  { 
    path: '/',
    title: "Login Page", 
    element: <Login />,
    public:true
  },
  { 
    path:'*',
    inexact: true,
    title: "Not found", 
    element: <PageNotFound/>, 
    public: true 
  },
]

export default routes;